import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

import BlockContentPrimary from "../components/blockContentPrimary"
import Tags from "../components/tags/Tags"
import { CustomSection } from "../components/CustomElements"
import Files from "../components/Files"
import ButtonTemplate from "../components/buttons/ButtonTemplate"
import TagsSection from "../components/tags/TagsSection"

const CustomPage = ({ data }) => {
  const {
    files,
    title,
    _rawMainContent: rawMainContent,
    refs,
  } = data.sanityCustomPages

  return (
    <Layout>
      {/* Change this to be dynamic based on title of job posting */}
      <SEO title={title} />
      {/* Main conent of jobs page */}
      <div className="flex flex-col items-center mt-10 md:mt-20 mx-3  font-light">
        <h1 className="text-center text-blue-dark mt-5">{title}</h1>

        <CustomSection className="w-full md:w-3/4 ">
          <BlockContentPrimary blockData={rawMainContent} />
        </CustomSection>

        {files.length >= 1 && <Files files={files} />}

        <ButtonTemplate
          to="/"
          text="Home"
          className="text-white uppercase tracking-wide mt-8"
          index={1}
        />
        {/* <div className="md:w-3/4 mb-5">
          {allTags.length >= 1 && <Tags tags={allTags} />}
        </div> */}
        <CustomSection className="w-full mx-5 mt-5">
          <TagsSection refs={refs} />
        </CustomSection>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CustomPageQuery($slug: String!) {
    sanityCustomPages(slug: { current: { eq: $slug } }) {
      id
      slug {
        current
      }
      title
      _rawMainContent(resolveReferences: { maxDepth: 10 })
      files {
        asset {
          id
          url
          originalFilename
        }
      }
      refs {
        communityReference {
          communityName
          slug {
            current
          }
        }
        departmentReference {
          departmentName
          slug {
            current
          }
        }
      }
    }
  }
`

export default CustomPage
